:root {
  --primary-color: #0a1118;
  --primary-color-highlight: #233D54;
  --light-gray: #585858;
  --bs-body-bg: #f5f5f7;
  --light-body-bg: #fff;
  --bs-body-font-family: 'Montserrat', sans-serif;
}

html {scroll-behavior: smooth;}

a { color: var(--primary-color); }
a:hover { color: var(--primary-color); }

::-webkit-scrollbar {
  width: 14px;
  display: none;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: var(--bs-dark); 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
}

h1 {
  letter-spacing: 5px;
}


.container-custom {
  max-width: 1480px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;

  @media (max-width: 768px) {
    max-width: 100%;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .container-custom { width: 1140px; }
}

@media (min-width: 1400px) {
  .container-custom { width: 1340px; }
}

@media (min-width: 1600px) {
  .container-custom { width: 1520px; }
}


.logo{
  width: 30px;
  height: auto;
}

.header {
  z-index: 2;
  background-color: var(--light-body-bg);
}

.custom-burger, .custom-burger__close{
  color: white !important;
  background-color: var(--light-gray) !important;
}

.collapse_links {
  font-size: 17px;
  gap: 2rem !important;
}

.hero {
  position: relative;
  height: calc(100vh - 4rem);
  background-color: var(--primary-color);
  overflow: hidden;
}

.hero .hero__image{
  height: 550px;
  width: auto;
  position: absolute;
  z-index: -10;

  @media (min-width: 375px) {
    right: -7rem;
    height: 450px;
  }

  @media (min-width: 768px) {
    right: -3rem;  
  }

  @media (min-width: 1024px) {
    right: 0;
  }

  @media (min-width: 1400px) {
    height: 600px;
  }
}

.offer-text{
  z-index: 10;
  width: 50%;
}

.hero__heading {
  @media (min-width: 768px) {
        font-size: 4rem;
  }
  @media (min-width: 1400px) {
        font-size: 6rem;
  }
}

.hero__subtitle {
  font-size: 20px;

  @media (max-width: 425px) {
    font-size: 13px;
  }

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1400px) {
        font-size: 28px;
    }
}

.herobutton {
  @media (max-width: 425px) {
    font-size: 13px;
  }
}

.hero__overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-color);
  opacity: 0.5;
  z-index: 1;
}

.hero__content {
  z-index: 1;
}

.hero__scroll-btn {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  z-index: 1;
  color: var(--bs-light);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.hero__scroll-btn:hover {
  color: var(--bs-light);
  opacity: 0.8;
}

.hero__scroll-btn .bi {
  transition-delay: 0.8s;
  animation: bounce 1s infinite alternate;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-10px);
  }
}


.block {
  padding: 1rem;
  @media (min-width: 1200px){
    padding: 8rem 1rem;
    height: 100vh;
    display: flex;
    place-items: center;
    justify-content: center;
  }
}

.photo {
    border-radius: 10%; 
    overflow: hidden; 
}

.block__section-thumbnail {
  object-fit: cover;
  margin: 0 auto;
  height: 400px;
  width: auto;
  border-radius: 10%;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 700px;
    width: 650px;
  }

  @media (min-width: 1024px) {
    height: 600px;
    width: 550px;
  }

  @media (min-width: 1200px) {
    height: 600px;
    width: 550px;
  }
}

.block__content-width {
  max-width: 650px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 0 0 0 2rem;
  }

  @media (min-width: 1200px) {
    max-width: 550px;
  }
}

.block--background {
  background-color: var(--light-body-bg);
}


#form {
  max-width: 100%;
  margin: 0 auto;
  @media (min-width: 1200px) {
    max-width: 60%;
  }
}

.frm-section {
  background-color: var(--light-body-bg);
}

#button {
  width: 100%;
  transition: all ease-in-out 0.3s;
  @media (min-width: 1024px) {
    width: 200px;
  }
}

#button:hover {
  box-shadow: 0px 4px 20px 0px #444444;
}


.footer .border-highlight {
  border-top: 1px solid var(--primary-color-highlight);
}

.circle-1 {
  z-index: -20;
  position: absolute;
  border-radius: 50%;
  width: 337px;
  height: 313px;
  background: rgba(47, 77, 181, 0.35);
  right: 12rem;
  filter: blur(80px);
}

.circle-2 {
  z-index: -20;
  position: absolute;
  border-radius: 50%;
  width: 337px;
  height: 313px;
  background: rgba(181, 47, 104, 0.46);
  right: -63px;
  filter: blur(80px);
  top: 30rem;
}

.moving {
  transform: translateX(-20rem);
}

