:root {
  --primary-color: #0a1118;
  --primary-color-highlight: #233d54;
  --light-gray: #585858;
  --bs-body-bg: #f5f5f7;
  --light-body-bg: #fff;
  --bs-body-font-family: "Montserrat", sans-serif;
}

html {
  scroll-behavior: smooth;
}

a, a:hover {
  color: var(--primary-color);
}

::-webkit-scrollbar {
  width: 14px;
  display: none;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--bs-dark);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1 {
  letter-spacing: 5px;
}

.container-custom {
  max-width: 1480px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 768px) {
  .container-custom {
    width: auto;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container-custom {
    width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-custom {
    width: 1340px;
  }
}

@media (min-width: 1600px) {
  .container-custom {
    width: 1520px;
  }
}

.logo {
  width: 30px;
  height: auto;
}

.header {
  z-index: 2;
  background-color: var(--light-body-bg);
}

.custom-burger, .custom-burger__close {
  color: #fff !important;
  background-color: var(--light-gray) !important;
}

.collapse_links {
  font-size: 17px;
  gap: 2rem !important;
}

.hero {
  background-color: var(--primary-color);
  height: calc(100vh - 4rem);
  position: relative;
  overflow: hidden;
}

.hero .hero__image {
  z-index: -10;
  width: auto;
  height: 550px;
  position: absolute;
}

@media (min-width: 375px) {
  .hero .hero__image {
    height: 450px;
    right: -7rem;
  }
}

@media (min-width: 768px) {
  .hero .hero__image {
    right: -3rem;
  }
}

@media (min-width: 1024px) {
  .hero .hero__image {
    right: 0;
  }
}

@media (min-width: 1400px) {
  .hero .hero__image {
    height: 600px;
  }
}

.offer-text {
  z-index: 10;
  width: 50%;
}

@media (min-width: 768px) {
  .hero__heading {
    font-size: 4rem;
  }
}

@media (min-width: 1400px) {
  .hero__heading {
    font-size: 6rem;
  }
}

.hero__subtitle {
  font-size: 20px;
}

@media (max-width: 425px) {
  .hero__subtitle {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .hero__subtitle {
    margin-bottom: 40px;
  }
}

@media (min-width: 1400px) {
  .hero__subtitle {
    font-size: 28px;
  }
}

@media (max-width: 425px) {
  .herobutton {
    font-size: 13px;
  }
}

.hero__overlay {
  background-color: var(--primary-color);
  opacity: .5;
  z-index: 1;
  position: absolute;
  inset: 0;
}

.hero__content {
  z-index: 1;
}

.hero__scroll-btn {
  z-index: 1;
  color: var(--bs-light);
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.hero__scroll-btn:hover {
  color: var(--bs-light);
  opacity: .8;
}

.hero__scroll-btn .bi {
  transition-delay: .8s;
  animation: 1s infinite alternate bounce;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-10px);
  }
}

.block {
  padding: 1rem;
}

@media (min-width: 1200px) {
  .block {
    justify-content: center;
    place-items: center;
    height: 100vh;
    padding: 8rem 1rem;
    display: flex;
  }
}

.photo {
  border-radius: 10%;
  overflow: hidden;
}

.block__section-thumbnail {
  object-fit: cover;
  border-radius: 10%;
  width: auto;
  height: 400px;
  margin: 0 auto;
  overflow: hidden;
}

@media (min-width: 768px) {
  .block__section-thumbnail {
    width: 650px;
    height: 700px;
  }
}

@media (min-width: 1024px) {
  .block__section-thumbnail {
    width: 550px;
    height: 600px;
  }
}

@media (min-width: 1200px) {
  .block__section-thumbnail {
    width: 550px;
    height: 600px;
  }
}

.block__content-width {
  max-width: 650px;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .block__content-width {
    padding: 0 0 0 2rem;
  }
}

@media (min-width: 1200px) {
  .block__content-width {
    max-width: 550px;
  }
}

.block--background {
  background-color: var(--light-body-bg);
}

#form {
  max-width: 100%;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  #form {
    max-width: 60%;
  }
}

.frm-section {
  background-color: var(--light-body-bg);
}

#button {
  width: 100%;
  transition: all .3s ease-in-out;
}

@media (min-width: 1024px) {
  #button {
    width: 200px;
  }
}

#button:hover {
  box-shadow: 0 4px 20px #444;
}

.footer .border-highlight {
  border-top: 1px solid var(--primary-color-highlight);
}

.circle-1 {
  z-index: -20;
  filter: blur(80px);
  background: #2f4db559;
  border-radius: 50%;
  width: 337px;
  height: 313px;
  position: absolute;
  right: 12rem;
}

.circle-2 {
  z-index: -20;
  filter: blur(80px);
  background: #b52f6875;
  border-radius: 50%;
  width: 337px;
  height: 313px;
  position: absolute;
  top: 30rem;
  right: -63px;
}

.moving {
  transform: translateX(-20rem);
}

/*# sourceMappingURL=index.cc41906a.css.map */
